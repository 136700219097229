import {Controller} from "stimulus";

export default class extends Controller {
    connect() {
        // Load polymorphic data from data-* attributes
        this.isVendor = this.data.get("isVendor") === "true"; // Read vendor status as a boolean
        this.vendorableId = this.data.get("vendorableId"); // Fetch the ID of the polymorphic object
        this.vendorableType = this.data.get("vendorableType"); // Fetch the polymorphic type (e.g., "Business" or "Contact")

        // Set the initial SVG icon color based on vendor status
        this.updateIconColor(this.isVendor ? "green" : "lightgray");
    }

    toggleVendor(event) {
        event.preventDefault();

        if (this.isVendor) {
            // The entity is a vendor; update to remove vendor status
            this.removeVendor()
                .then(() => {
                    this.isVendor = false;
                    this.updateIconColor("lightgray"); // Change the icon color to gray
                })
                .catch((error) => {
                    console.error("Failed to remove vendor:", error);
                    alert("Error: Could not remove vendor. Please try again.");
                });
        } else {
            // The entity is not a vendor; create the vendor record
            this.createVendor()
                .then(() => {
                    this.isVendor = true;
                    this.updateIconColor("green"); // Change the icon color to green
                })
                .catch((error) => {
                    console.error("Failed to create vendor:", error);
                    alert("Error: Could not create vendor. Please try again.");
                });
        }
    }

    async createVendor() {
        const response = await fetch(`/accounts_payable/vendors`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": document.querySelector("[name='csrf-token']").content
            },
            body: JSON.stringify({
                vendorable_id: this.vendorableId,
                vendorable_type: this.vendorableType
            })
        });

        if (!response.ok) {
            throw new Error("Failed to create vendor. Response status: " + response.status);
        }
        this.showFlashMessage("The " + this.vendorableType + " has been successfully activated as a vendor!", "success");

    }


    async removeVendor() {
        const response = await fetch(`/accounts_payable/vendors/remove`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": document.querySelector("[name='csrf-token']").content
            },
            body: JSON.stringify({
                vendorable_id: this.vendorableId,
                vendorable_type: this.vendorableType
            })
        });

        if (!response.ok) {
            throw new Error("Failed to remove vendor. Response status: " + response.status);
        }
        this.showFlashMessage("The vendor status has been successfully removed.", "success");

    }

    updateIconColor(color) {
        this.element.querySelector(".vendor-icon-svg").style.fill = color;
    }

    showFlashMessage(message, type) {
        const flashDiv = document.getElementById("flash-area")
        flashDiv.innerHTML = '';

        // Create a new flash message element
        const messageDiv = document.createElement("div");
        messageDiv.className = type === "success" ? "notice" : "alert";
        messageDiv.textContent = message;

        // Append the message to the flash area
        flashDiv.appendChild(messageDiv);

        // Remove after a delay (e.g., 3 seconds)
        setTimeout(() => {
            flashDiv.innerHTML = "";
        }, 3000);
    }
}