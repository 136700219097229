import {Controller} from 'stimulus'
import SlimSelect from 'slim-select'

export default class extends Controller {
    connect() {
        this.slimSelect = new SlimSelect({
            select: this.element,
            addToBody: true,
            onChange: () => {
                // Automatically return focus to slimWrapper after selection
                this.returnFocusToWrapper();
            }
        });
        // Make the SlimSelect dropdown tabbable
        const slimWrapper = this.element.nextElementSibling; // SlimSelect wrapper
        console.dir(slimWrapper);
        // Wait for SlimSelect to initialize its DOM and then attach listeners
        setTimeout(() => {
            // Fetch SlimSelect's wrapper (DOM dynamically created by SlimSelect)
            const slimWrapper = this.element.nextElementSibling;

            if (slimWrapper) {
                this.slimWrapper = slimWrapper;

                // Ensure slimWrapper is tabbable
                slimWrapper.setAttribute("tabindex", "0");

                // Add event listeners dynamically
                slimWrapper.addEventListener("focus", () => {
                    this.slimSelect.open(); // Open on focus
                });
                const wrapperFirstClass = slimWrapper.classList[0];
                const contentElement = document.querySelector(`.${wrapperFirstClass}.ss-content`);

                contentElement.addEventListener("keydown", (event) => {
                    if (event.key === "Enter" || event.key === " ") {
                        this.slimSelect.open(); // Open SlimSelect on Enter/Space
                    } else if (event.key === "Tab") {
                        // Handle Tab to go to the next element
                        this.moveToNextTabbableElement(event, slimWrapper);
                    }
                });
            } else {
                console.error("SlimSelect wrapper not found.");
            }
        }, 0); // Slight delay to ensure DOM has updated
    }

    disconnect() {
        console.log("Disconnecting SlimSelect");

        // Check if slimWrapper exists in the DOM before destroying
        if (this.slimWrapper && document.body.contains(this.slimWrapper)) {
            try {
                this.slimSelect.destroy(); // Safely destroy SlimSelect
                console.log("SlimSelect successfully destroyed");
            } catch (error) {
                console.error("Error destroying SlimSelect:", error);
            }
        } else {
            console.warn("SlimSelect wrapper was already removed or not found.");
        }

        this.slimWrapper = null; // Clean up wrapper reference
    }

    // Function to handle `Tab` key movement
    moveToNextTabbableElement(event, currentElement) {
        event.preventDefault(); // Prevent the default tab navigation

        // Find all tabbable elements in the document
        const tabbableElements = Array.from(
            document.querySelectorAll(
                'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
            )
        ).filter(
            (el) =>
                !el.hasAttribute("disabled") && el.offsetParent !== null // Filter visible and non-disabled elements
        );

        // Get the current element's index in the tabbable elements
        const currentIndex = tabbableElements.indexOf(currentElement);

        // Find the next tabbable element
        const nextElement = tabbableElements[currentIndex + 1];

        if (nextElement) {
            nextElement.focus(); // Focus the next element in the tab order
        }
    }

    returnFocusToWrapper() {
        if (this.slimWrapper) {
            console.log("Returning focus to slimWrapper");
            this.slimWrapper.focus(); // Explicitly set focus back to slimWrapper
        } else {
            console.error("SlimWrapper is not properly initialized.");
        }
    }
}