import {Controller} from "stimulus";

export default class extends Controller {

    connect() {
        console.log("log from bank-payment-type-load controller");
    }


}
