import {Controller} from "stimulus"
import AutoNumeric from "autonumeric";
// import Honeybadger from 'honeybadger-js';

let autoNumericList = [];
let autoNumericBalance;
let autoNumericCartTotal;
let cartIdentifier;
let loopRunning = false;
export default class extends Controller {

    connect() {
        console.log("log from checkoutPayments controller");
        const amountDue = Number(window.checkoutMiniCartCartTotal.innerHTML.replace("$", "").replace(",", "").trim());
        let tenderedFields = document.querySelectorAll(".tendered-field")
        let workingTenderedField = tenderedFields[tenderedFields.length - 1];
        cartIdentifier = this.data.get("cart");
        workingTenderedField.value = amountDue;
        autoNumericBalance = new AutoNumeric('#balanceAmount', {currencySymbol: '$'});
        autoNumericCartTotal = new AutoNumeric('#cart-total', {currencySymbol: '$'});
        updateBalance();
        autoNumericList = new AutoNumeric.multiple(".tendered-field", ['NorthAmerican', {unformatOnSubmit: true}]);
        window.miniTeller.classList.add('teller-show')
    }

    addPayment(e) {
        const paymentsArea = document.querySelector(".co-working-payment");
        console.log("payment addeded!");
        fetch("/cr_payments/add_payment?cart=" + cartIdentifier)
            .then(response => response.text())
            .then(html => {
                    console.log(html);
                    paymentsArea.insertAdjacentHTML("beforeEnd", html);
                    paymentsArea.lastElementChild.querySelector(".tendered-field").value = autoNumericBalance.getNumericString();
                    new AutoNumeric(paymentsArea.lastElementChild.querySelector(".tendered-field"), ['NorthAmerican', {unformatOnSubmit: true}]);
                    updateBalance();
                }
            );

    }

    postDateSelect(e) {
        console.log("posting date changed!");
        const postingDate = document.getElementById('when_booked').value;
        const postDateArea = e.target.closest(".posting-date-form");
        fetch("/carts/" + cartIdentifier + "/refresh_cart?booked_on=" + postingDate)
            .then(response => response.text())
            .then(html => {
                    document.querySelector('.cart-detail').innerHTML = html;
                    updateBalance();
                    let newPostingDate = new Date(postingDate + ' est');
                const today = new Date();
                    const dateDisplay = postDateArea.closest(".posting-date-control").querySelector(".posting-date-display");
                    dateDisplay.innerText = newPostingDate.toLocaleDateString("en-US");
                    if (newPostingDate.getDate() === today.getDate()) {
                        dateDisplay.classList.remove('alert-date')
                    } else {
                        dateDisplay.classList.add('alert-date')
                    }

                    postDateArea.classList.remove("editing");
                    document.querySelector(".flatpickr-calendar").classList.remove("open");
                }
            )
    }

    postStripe() {
        if (loopRunning === true) {
            console.log("Already Checking; aborting...")
        } else {
            loopRunning = true;
            console.log("starting check for stripe completion")
            loopPaymentlinesCheck(cartIdentifier)
        }

    }

    addAdjustment(e) {
        const cartItem = e.target.closest(".cart-row").querySelector(".cart-item-id")
        const cartItemId = makeNumber(cartItem.innerHTML);
        console.log("adjustment addeded!");
        fetch("/cr_adjustments/add_adjustment?cart_item_id=" + cartItemId)
            .then(response => response.text())
            .then(html => {
                    cartItem.closest("td").insertAdjacentHTML("beforeEnd", html);
                    const adjustmentFields = document.querySelectorAll(".adjustment-field");
                    const lastAdjustmentField = adjustmentFields[adjustmentFields.length - 1];
                    new AutoNumeric(lastAdjustmentField, ['NorthAmerican', {unformatOnSubmit: true}]);
                    updateBalance();
                    updateCartItemAdjustedBalances();
                }
            );
    }

    adjustmentSubmitEnable(e) {
        const formArea = e.target.closest(".cr-adjustment-fields");
        const depositAmountPresent = AutoNumeric.getNumber(formArea.querySelector(".cr-adj-amount"));
        const adjustmentMemoDescriptionPresent = formArea.querySelector(".cr-adj-description").value.length > 0
        const memoSubmitButton = formArea.querySelector(".cr-adj-add-button");
        memoSubmitButton.disabled = !(adjustmentMemoDescriptionPresent && depositAmountPresent);

    }


    removeAdjustment(e) {
        const adjustmentLine = e.target.closest('.cr-adjustment-line')
        adjustmentLine.parentNode.removeChild(adjustmentLine);
        console.log("adjustment removed!");
        updateCartItemAdjustedBalances();
        updateBalance();
        updatePayment();
    }


    updateBalance(e) {
        e.preventDefault();
        updateBalance()
        autoNumericList.map(function (element) {
            element.set
        });
    }

    submitAdjustment(e) {
        e.preventDefault();
        const descriptionField = e.target.closest(".cr-adjustment-fields").querySelector(".cr-adj-description");
        const amountField = AutoNumeric.unformat(e.target.closest(".cr-adjustment-fields").querySelector(".cr-adj-amount"), 'NorthAmerican');
        const cartItemId = e.target.closest(".cart-row").querySelector(".cart-item-id").innerHTML;
        if (amountField.length > 0 && descriptionField.value.length > 0) {
            fetch("/cr_adjustments?cart_item_id=" + cartItemId, {
                method: 'POST',
                credentials: "same-origin",
                headers: {
                    "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({amount: amountField, description: descriptionField.value})
            }).then(response => response.text())
                .then((html) => {
                    console.log('adjustment created; updating cart');
                    document.querySelector(".cart-detail").innerHTML = html;
                    updateCartItemAdjustedBalances();
                    updateBalance();
                    updatePayment();
                })
                .catch((error) => {
                        console.warn(error);
                    // Honeybadger.notify(error)
                        document.querySelector('#flash-area').innerHTML = "<p class=\"notice\">" + error.message + "</p>";

                    }
                )
        }
    }

    removePaymentLine(e) {
        const paymentLine = e.target.closest(".payment-line");
        paymentLine.parentNode.removeChild(paymentLine);
        updateBalance();
        updatePayment();
    }

    setPaymentFields(e) {
        console.log("paymentField Changed");
        let payLineArea = e.target.closest(".payment-line");
        const model = payLineArea.querySelector(".payment-select").value;
        const currentAmount = makeNumber(payLineArea.querySelector(".tendered-field").value);
        var template = document.createElement('template');
        fetch("/cr_payments/add_payment?type=" + model + "&amount_tendered=" + currentAmount + "&cart=" + cartIdentifier)
            .then(response => response.text())
            .then(html => {
                html = html.trim();
                template.innerHTML = html
                const insertElement = template.content.firstChild;
                payLineArea.parentNode.replaceChild(insertElement, payLineArea)
                const paymentField = new AutoNumeric(insertElement.lastElementChild.querySelector(".tendered-field"), ['NorthAmerican', {unformatOnSubmit: true}]);
                paymentField.set(currentAmount);
                updateBalance();
            });
    }

    applyPayments(e) {
        console.log("checking for negative non-cash balance")
        if (makeNumber(document.getElementById("balanceAmount").innerText) < 0.0 &&
            !sufficientCashForChange()
        ) {
            if (!confirm("The transaction has less cash than the total change due.  Proceed?")) {
                return false
            }
        }
        console.log("applying payments")
        e.target.disabled = true;
        postPayments(cartIdentifier);
    }


    waivePenalty(e) {
        e.preventDefault();
        const penaltyId = e.target.closest(".cr-adjustment-line").querySelector(".penalty-id").value;
        const postingDate = document.getElementById('when_booked').value;
        fetch("/carts/" + cartIdentifier + "/waive_penalty?penalty_id=" + penaltyId + "&booked_on=" + postingDate)
            .then(response => response.text())
            .then(html => {
                document.querySelector('.cart-detail').innerHTML = html;
                updateBalance();
                updatePayment();
                console.log("waiving penalty");
                return false;
            });
        return false;
    }

}

function updateBalance() {
    console.log("updating balance");
    const totalDue = amountPaid(document.querySelectorAll(".line-amount")) +
        amountPaid(document.querySelectorAll(".adjustment-field")) +
        amountPaid(document.querySelectorAll(".adjustment-line-amount")) -
        amountPaid(document.querySelectorAll(".paid-amount"))
    const totalPaying = amountPaid(document.querySelectorAll(".tendered-field"));
    const balance = (totalDue - totalPaying).toFixed(2)
    document.getElementById('cart-total').innerText = totalDue.toFixed(2);
    autoNumericBalance.set(balance);
    autoNumericCartTotal.set(totalDue);
    if (paymentLinesReady() && tellerAssigned()) {
        document.querySelector("#apply-payments-button").classList.remove('hidden')
    } else {
        document.querySelector("#apply-payments-button").classList.add('hidden')

    }
}

function updateCartItemAdjustedBalances() {
    Array.from(document.querySelectorAll(".cart-row")).map(el => {
            if (el.querySelector(".adjusted-line-amount")) {
                const initialAmount = amountPaid(el.querySelectorAll(".line-amount"))
                const adjustments = amountPaid(el.querySelectorAll(".adjustment-line-amount"))
                if (adjustments !== 0) {
                    el.querySelector(".adjusted-line-amount").innerText = "$" + (initialAmount + adjustments).toFixed(2)
                } else {
                    el.querySelector(".adjusted-line-amount").innerText = ""
                }
            }
        }
    )
}

function amountPaid(elements) {
    // console.log(elements);
    const totalAmount = Array.prototype.slice.call(elements).reduce(function (total, element) {
        let stringValue;
        if (element.value) {
            stringValue = element.value
        } else {
            stringValue = element.innerText
        }
        // console.log("adding: " + stringValue)
        return total + makeNumber(stringValue);
    }, 0.0);
    return totalAmount;
}


function paymentLinesReady() {
    return Array.prototype.slice.call(document.querySelectorAll(".ready-to-pay")).every(function (element) {
        return element.innerText == "true"
    })
}

function paymentCancelled() {
    return (document.querySelector(".cc-manual-fields").querySelector("#cancelled").value == "true")
}

function loopPaymentlinesCheck(cartIdentifier) {
    console.log("checking if stripe is ready")
    if (paymentCancelled()) {
        loopRunning = false;
        return
    } else if (paymentLinesReady()) {
        loopRunning = false;
        updateBalance();
        if (cartFullyPaid()) {
            postPayments(cartIdentifier);
        } else {
            const oldPaymentArea = document.querySelector(".stripe-payment-form-area");
            const oldbutton = document.querySelector(".card-detail-button");
            oldPaymentArea.classList.remove("stripe-payment-form-area");
            oldPaymentArea.classList.add("stripe-payment-form-area-completed");
            oldbutton.classList.remove("card-detail-button");
        }
        return
    } else {
        setTimeout(function () {
            loopPaymentlinesCheck(cartIdentifier)
        }, 500)
    }
}

function tellerAssigned() {
    return document.getElementById('current_teller_id').value.length > 0;
}

function collectPayments() {
    return Array.prototype.slice.call(document.querySelector(".payments-area").querySelectorAll(".payment-partial")).map(function (paymentLine) {
        const model = paymentLine.querySelector(".klass").innerText;
        const valueSet = Array.prototype.slice.call(paymentLine.querySelectorAll("input[type=text], input[type=hidden]")).map(function (inputSet) {
            let tempSet = {};
            let cleanValue = "";
            if (inputSet.value[0] === "$" || inputSet.value[1] === "$") {
                cleanValue = makeNumber(inputSet.value)
            } else {
                cleanValue = inputSet.value
            }
            tempSet[inputSet.name] = cleanValue;
            return tempSet
        });
        let returnHash = {};
        returnHash['klass'] = model;
        returnHash['params'] = valueSet;
        return returnHash;
    });
}

function makeNumber(textVal) {
    return Number(textVal.replace("$", "").replaceAll(",", "").trim());
}

function cartFullyPaid() {
    return makeNumber(document.getElementById('balanceAmount').innerText) === 0.0
}

function updatePayment() {
    if (!cartFullyPaid() && document.querySelectorAll(".tendered-field").length == 1) {
        console.log("matching payment to total");
        const tenderedField = AutoNumeric.getAutoNumericElement(".tendered-field");
        const tenderedDomField = document.querySelector(".tendered-field");
        var event = new Event('change');
        tenderedField.set(document.getElementById('cart-total').innerText);
        tenderedDomField.dispatchEvent(event);
        updateBalance();
    }
}

function sufficientCashForChange() {
    return amountPaid(document.querySelectorAll(".cash-payment")) >= (makeNumber(document.getElementById("balanceAmount").innerText) * -1)
}

function postPayments(cartIdentifier) {
    document.getElementById("apply-payments-button").setAttribute("disabled", "disabled");
    let paymentsJSON = collectPayments();
    const totalDue = makeNumber(document.getElementById('cart-total').innerText);
    const totalPaying = amountPaid(document.querySelectorAll(".tendered-field"));
    const balance = (totalDue - totalPaying).toFixed(2);
    const whenBooked = document.getElementById('when_booked').value;
    let selectedRecipients = [];
    if (document.getElementById('recipients')) {
        selectedRecipients = Array.from(document.getElementById('recipients').options).filter(option => option.selected).map(option => option.value);
    }
    let emailRecipients = selectedRecipients.concat(document.getElementById('dynamic_recipient').value.split(/[;,]/).map(e => e.trim()));
    let checkout = {klass: "Checkout", params: {}};
    checkout['params']['cart_id'] = cartIdentifier;
    checkout['params']['totalDue'] = totalDue;
    checkout['params']['totalPaying'] = totalPaying;
    checkout['params']['balance'] = balance;
    checkout['params']['when_booked'] = whenBooked;
    checkout['params']['recipients'] = emailRecipients;
    paymentsJSON.push(checkout);
    console.log(paymentsJSON);
    fetch('/carts/' + cartIdentifier + '/checkouts', {
        method: 'POST',
        credentials: "same-origin",
        headers: {
            "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(paymentsJSON)
    }).then(response => {
        if (!response.ok) {
            response.text().then(text => {
                document.getElementById('flash-area').innerHTML = "<p class=\"alert\">" + JSON.parse(text).message + "</p>";
                }
            )

        }
        return response.json();
    }).then((data) => {
        console.log(data);
        Turbo.cache.clear();
        if (data['result'] === "success") {
            document.querySelector('#flash-area').innerHTML = "<p class=\"notice\">" + data['message'] + "</p>";
            document.querySelector('#apply-payments-button').classList.add('hidden');
            window.checkoutMiniCart.classList.add('hidden');
            document.querySelector(".co-working-payment").classList.add('hidden');
            document.querySelector(".co-add-payment").classList.add('hidden');
            document.querySelector(".co-working-payment").classList.add('hidden');
            document.querySelector(".email-receipt").classList.add('hidden');
            Array.from(document.querySelectorAll('.waive-penalty')).map(function (el) {
                el.classList.add('hidden')
            })
            const hideElements = Array.from(document.querySelectorAll(".remove-cart-icon")).concat(Array.from((document.querySelectorAll(".add-adjustment-control"))))
            hideElements.forEach(function (el) {
                el.classList.add('hidden');
            })
            data['html'].map(function (partial) {
                window.postControls.insertAdjacentHTML("beforeEnd", partial);
            })
        } else {
            if (data['result'] === 'retry') {
                document.getElementById('apply-payments-button').disabled = false;
            }
            document.getElementById('flash-area').innerHTML = "<p class=\"alert\">" + data['message'] + "</p>";
        }
    })
        .catch((error) => {
            console.warn(error);
            // Honeybadger.notify(error)
            document.querySelector('#flash-area').innerHTML = "<p class=\"notice\">" + error.message + "</p>";
        })

}