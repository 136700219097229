import {Controller} from "stimulus"

export default class extends Controller {
    connect() {
        console.log("tailwind-modal-controller");

    }


    toggleModal(event) {
        event.preventDefault();
        console.log("toggle modal");
        let modal = event.target.closest(".tailwind-modal-area").querySelector('.tailwind-modal');
        modal.classList.toggle('hidden');
    }


}

