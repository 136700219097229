// app/javascript/controllers/gl_entry_dates_controller.js
import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["bookedDate", "chargedDate", "description"]

    connect() {
        // optional - you can use this for debugging
        // console.log("GL Entry Dates controller connected")
    }

    syncBookedDate(event) {
        const currentRow = this.element
        let nextRow = currentRow.nextElementSibling

        while (nextRow) {
            const bookedDateField = nextRow.querySelector('input[name*="when_booked"]')
            if (bookedDateField) {
                bookedDateField.value = event.target.value
                bookedDateField.dispatchEvent(new Event('change', {bubbles: true}))
            }
            nextRow = nextRow.nextElementSibling
        }
    }

    syncChargedDate(event) {
        const currentRow = this.element
        let nextRow = currentRow.nextElementSibling

        while (nextRow) {
            const chargedDateField = nextRow.querySelector('input[name*="when_charged"]')
            if (chargedDateField) {
                chargedDateField.value = event.target.value
                chargedDateField.dispatchEvent(new Event('change', {bubbles: true}))
            }
            nextRow = nextRow.nextElementSibling
        }
    }

    syncDescription(event) {
        const currentRow = this.element
        let nextRow = currentRow.nextElementSibling

        while (nextRow) {
            const descriptionField = nextRow.querySelector('input[name*="description"]')
            if (descriptionField && descriptionField.value.trim() === '') {
                descriptionField.value = event.target.value
                descriptionField.dispatchEvent(new Event('change', {bubbles: true}))
            }
            nextRow = nextRow.nextElementSibling
        }
    }
}