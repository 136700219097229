import {Controller} from "stimulus";
// import Honeybadger from "honeybadger-js";

export default class extends Controller {

    connect() {
        console.log("log from fee-controller");
    }

    build(e) {
        e.preventDefault();
        console.log("building a fee");
        let data = {
            fee_type: e.target.dataset.feeType,
            parent_klass: e.target.dataset.klass,
            charge: e.target.dataset.amount
        }
        let feeableId = '';
        if (document.getElementById('bl_license_application_id')) {
            feeableId = document.getElementById('bl_license_application_id').value
        }
        console.log(data);
        fetch(window.location.href
            .replace('/new', `/${feeableId}`)
            .replace('/edit', '') + '/fees/build', {
            method: 'POST',
            credentials: "same-origin",
            headers: {
                "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((data) => {
                console.log(data);
                document.getElementById("checkoutMiniCartCartTotal").innerText = '$' + Number(data['amount_owed']).toFixed(2)
                document.getElementById("checkoutMiniCartCount").innerText = data['cart_item_count']
                document.getElementById("checkoutMiniCart").classList.add("cart-show");
                document.querySelector(".fees-popup").classList.remove("editing");
            })
            .catch((error) => {
                    console.warn(error);
                // Honeybadger.notify(error)
                    document.querySelector('#flash-area').innerHTML = "<p class=\"notice\">" + error.message + "</p>";
                }
            )
    }


}
