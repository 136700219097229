import {Controller} from "stimulus"


export default class extends Controller {

    connect() {
        console.log("log from ap-bill-vendor controller");
    }

    selectVendor(e) {
        console.log("selectVendor");
        const vendorField = document.getElementById("accounts_payable_bill_vendor_id")
        const vendorId = e.target.closest("li").id
        vendorField.value = vendorId;
        e.target.closest("li").classList.add("active");
        const resultsArea = e.target.closest(".search-container").querySelector(".bl-search-results");
        resultsArea.classList.remove("showing");
        resultsArea.innerHTML = '';
        const frame = document.querySelector("turbo-frame#bill_invoice_frame");
        frame.src = `/invoicing/invoices/new?vendor_id=${vendorId}`

    }
}