import {Controller} from "stimulus"

export default class extends Controller {
    change(event) {
        const form = this.element.closest('form')
        const itemsPerPage = document.createElement('input')
        itemsPerPage.type = 'hidden'
        itemsPerPage.name = 'items_per_page'
        itemsPerPage.value = event.target.value
        form.appendChild(itemsPerPage)
        form.submit()
    }
}