import {Controller} from "stimulus"
import LogRocket from 'logrocket';
// var Honeybadger = require('honeybadger');

export default class extends Controller {

    connect() {
        console.log("log from support controller");
        const currentId = document.querySelector("#current-id").innerHTML;
        const currentEmail = document.querySelector("#current-email").innerHTML;
        if (process.env.NODE_ENV === 'production') {
            LogRocket.identify(currentId, {
                email: currentEmail
            })
        }
        // Honeybadger.configure({
        //     apiKey: process.env.HONEYBADGER_API,
        //     environment: process.env.RAILS_ENV || 'development',
        // });

    }

}
